import { OrderModel, ClaimModel } from '@models';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { CREDIT_BONUS_TYPES } from '~/support/constants';
import { OrderService } from '~/support/services';

import { useClaimStore } from './claim';

export const useOrdersStore = defineStore(
  'orders',
  () => {
    const currentOrder = ref(null);
    const claim = useClaimStore();
    const { currentShipmentId } = storeToRefs(claim);
    const service = new OrderService(useRuntimeConfig().public.apiBaseUrl);

    async function find(email, orderNumber) {
      currentOrder.value = await service.find(email, orderNumber);

      claim.setCustomer({
        email,
        sourceOrderId: orderNumber,
      });

      return currentOrder.value;
    }

    function reset() {
      currentOrder.value = null;
    }

    async function fetchOrder(email, sourceOrderId) {
      const dbOrder = await find(email, sourceOrderId);

      if (!dbOrder) return;

      const newOrder = new OrderModel(dbOrder.order);
      newOrder.existingClaims = dbOrder.existingClaims;
      newOrder.store = dbOrder.store;
      currentOrder.value = newOrder;
    }

    const claimFeedClaims = computed(() => {
      if (!currentOrder.value.existingClaims) {
        return [];
      }

      return ClaimModel.fromArrayExtending(currentOrder.value?.existingClaims, {
        order: currentOrder.value,
      });
    });

    const formattedCreditBonus = computed(() => {
      if (currentOrder.value.settings.credit.bonus === null) return null;

      return currentOrder.value.settings.credit.bonusType === CREDIT_BONUS_TYPES.fixed
        ? new Intl.NumberFormat(navigator.language, {
            currency: currentOrder.value.order.currency,
            style: 'currency',
          }).format(currentOrder.value.settings.credit.bonus * currentOrder.value.order.exchangeRate)
        : `${currentOrder.value.settings.credit.bonus}%`;
    });

    const soonestFileDate = computed(() => {
      const [date] = currentOrder.value.fulfillments.map(({ eligibleAt }) => eligibleAt).sort();

      return date;
    });

    const eligibleFulfillments = computed(() => {
      return currentOrder.value.fulfillments.filter(({ eligible }) => eligible);
    });

    const tooSoonToFile = computed(() => {
      if (currentOrder.value.fulfillments.length === 0) {
        return false;
      }

      return currentOrder.value.fulfillments.every(({ eligible }) => !eligible);
    });

    const eligibleForClaim = computed(() => {
      return currentOrder.value.protected;
    });

    const allCurrentOrderItems = computed(() => {
      if (!currentOrder.value) return [];

      return currentOrder.value.fulfillments.reduce((all, fulfillment) => {
        return all.concat(
          fulfillment.items.map(({ item, quantity }) => ({
            ...item,
            quantity,
          })),
        );
      }, []);
    });

    const deliveredFulfillments = computed(() => {
      if (!currentOrder.value) return [];

      return currentOrder.value.fulfillments.filter(({ fulfillmentStatus }) => fulfillmentStatus === 'DELIVERED');
    });

    const hasDeliveredFulfillments = computed(() => {
      return deliveredFulfillments.value.length > 0;
    });

    const currentShipmentOrderItems = computed(() => {
      if (!currentOrder.value.fulfillments.length > 0 || !currentShipmentId.value) {
        return [];
      }

      return currentOrder.value.fulfillments
        .find(({ sourceFulfillmentId }) => sourceFulfillmentId === currentShipmentId.value)
        .items.map(({ item, quantity, sourceFulfillmentId }) => ({
          ...item,
          quantity,
          sourceFulfillmentId,
        }));
    });

    const originalAddress = computed(() => {
      return currentOrder.value.shippingAddress;
    });

    const orderHasShipped = computed(() => {
      return currentOrder.value.fulfillments.length > 0;
    });

    return {
      allCurrentOrderItems,
      claimFeedClaims,
      currentOrder,
      currentShipmentOrderItems,
      deliveredFulfillments,
      eligibleForClaim,
      eligibleFulfillments,
      fetchOrder,
      find,
      formattedCreditBonus,
      hasDeliveredFulfillments,
      orderHasShipped,
      originalAddress,
      reset,
      soonestFileDate,
      tooSoonToFile,
    };
  },
  {
    persist: {
      key: 'order',
    },
  },
);
