import { useWhiteLabelStore, useApplicationStore, useOrdersStore } from '~/store';

export default defineNuxtRouteMiddleware(async to => {
  const store = useWhiteLabelStore();
  const app = useApplicationStore();
  const orderStore = useOrdersStore();
  const hostname = window.location.hostname;

  const storeId = to.query.store || orderStore.currentOrder?.order?.storeId;
  const brandingSet = store.get('brand100');

  if (brandingSet) return app.setReady();

  await store.find(hostname === 'localhost' ? null : hostname, storeId || null);

  // Mark the application as ready
  app.setReady();
});
