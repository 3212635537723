import revive_payload_client_2JCs1ROTuy from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6MmvcmgH8b from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7Yuhn1iV4D from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_2OZOeGd8fN from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GA6aJG6AVb from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_I0mq4GVLxk from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_n5DXlAQlLb from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.21.2_typescript@5.0.4_vue@3.5.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/concierge/.nuxt/components.plugin.mjs";
import prefetch_client_r7dYsShOLS from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_oIOCIbRfSG from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.21.2_vue@3.5.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_JT5NrXjhzT from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.21.2_vue@3.5.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_C7kgpYUxWY from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@4.21.2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import hubble_ZhZhlCuj9J from "/app/apps/concierge/plugins/hubble.js";
import sentry_PZVGm6LH3G from "/app/apps/concierge/plugins/sentry.js";
export default [
  revive_payload_client_2JCs1ROTuy,
  unhead_6MmvcmgH8b,
  router_7Yuhn1iV4D,
  payload_client_2OZOeGd8fN,
  navigation_repaint_client_GA6aJG6AVb,
  chunk_reload_client_I0mq4GVLxk,
  plugin_vue3_n5DXlAQlLb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_r7dYsShOLS,
  switch_locale_path_ssr_oIOCIbRfSG,
  i18n_JT5NrXjhzT,
  plugin_C7kgpYUxWY,
  hubble_ZhZhlCuj9J,
  sentry_PZVGm6LH3G
]